<template>
  <b-card-body class="text-center">
    <div class="mt-2 mb-2">
      <h4>Related Products</h4>
    </div>
    <!-- Swiper -->
    <swiper
      class="swiper-responsive-breakpoints px-4 py-2"
      ref="mySwiper"
      :options="swiperOptions"
    >
      <swiper-slide
        style="
          background-color: #fbfbfb;
          padding: 20px;
          border-radius: 10px;
          height: 500px;
        "
        v-for="(product, index) in relatedData"
        :key="index"
      >
        <b-link>
          <div class="item-heading mt-1">
            <h5 class="text-truncate mb-1">
              {{ product.name }}
            </h5>
            <small style="font-size: 15px" class="text-body"
              >by {{ product.brand }}</small
            >
          </div>
          <div
            class="h-75 w-75"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin: auto;
            "
          >
            <b-img
              :src="product.image"
              class="img-fluid rounded"
              style="
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 70%;
                height: auto;
              "
            />
          </div>
          <div class="item-meta">
            <ul class="unstyled-list list-inline mb-25">
              <li v-for="star in 5" :key="star" class="ratings-list-item">
                <feather-icon
                  icon="StarIcon"
                  size="22"
                  class="mr-25"
                  :class="[
                    { 'fill-current': star <= product.rating },
                    star <= product.rating ? 'text-warning' : 'text-muted',
                  ]"
                />
              </li>
            </ul>
            <p style="font-size: 18px" class="card-text text-primary mb-0">
              ${{ product.price }}
            </p>
          </div>
        </b-link>
      </swiper-slide>
      <!-- Add Arrows -->
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
  </b-card-body>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 40,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  mounted() {
    this.swiper.slideTo(3, 1000, false)
  },
  props: ['relatedData'],
  setup() {
    /* eslint-disable global-require */
    /* eslint-disable global-require */
    return {}
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
</style>
