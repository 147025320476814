<template>
  <section class="app-ecommerce-details">
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- Content -->
    <b-card v-if="itemData" no-body>
      <b-badge
        variant="danger"
        class="p-1"
        style="font-size: 15px"
        v-if="!delCat"
      >
        Archived Data
      </b-badge>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="mainImage"
                :alt="`Image of ${itemTrans_name}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>
          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>
              <span style="color: #0d6efd">Name:</span>
              {{ itemTrans_name }} -
              {{ itemTrans_name2 }}
            </h4>
            <br />
            <h4>
              <span style="color: #0d6efd">SKU:</span>
              {{ sku }}
            </h4>

            <!-- <b-card-text class="item-company mb-0">
                            <h4><span style="color:#0D6EFD;">Sale Price: </span>{{price}}</h4>
                        </b-card-text> -->
            <br />
            <!-- <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                            <h4><span style="color:#0D6EFD;">Rate:</span></h4>
                            <ul class="unstyled-list list-inline pl-1">
                                <li v-for="star in 5" :key="star" class="ratings-list-item mr-25">
                                    <feather-icon icon="StarIcon" size="18" :class="[{'fill-current': star <= average_rate}, star <= average_rate ? 'text-warning' : 'text-muted']" />
                                </li>
                            </ul>
                        </div> -->
            <!-- <br /> -->
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #0d6efd">Brand:</span>
                <b-link
                  style="color: black"
                  :to="{ path: '/Show-Brand/' + brand_id }"
                >
                  {{ brandTrans_name }}
                </b-link>
              </h4>
            </b-card-text>
            <br />
            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #0d6efd">Category:</span>
                <b-link
                  style="color: black"
                  :to="{ path: '/Show-Category/' + cat_id }"
                >
                  {{ catTrans_name }}
                </b-link>
              </h4>
            </b-card-text>
            <br />
            <!-- Product Short Description -->
            <h5>Short Description:</h5>
            <b-card-text
              class="ml-3"
              v-for="(item, index) in itemTrans"
              :key="index"
            >
              <p v-html="item.short_description"></p>
            </b-card-text>
            <br />
            <!-- Avability -->
            <b-card-text v-if="itemData.status === 'confirmed'">
              <span style="color: #0d6efd">Status</span>
              -
              <b-badge variant="success">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'enabled'">
              <span style="color: #0d6efd">Status</span>
              -
              <b-badge variant="success">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'disabled'">
              <span style="color: #0d6efd">Status</span>
              -
              <b-badge variant="danger">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'review'">
              <span style="color: #0d6efd">Status</span>
              -
              <b-badge variant="warning">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'rejected'">
              <span style="color: #0d6efd">Status</span>
              -
              <b-badge variant="dark">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'blocked'">
              <span style="color: #0d6efd">Status</span>
              -
              <b-badge variant="dark">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text>
              <span style="color: #0d6efd">Sales Channel</span>
              -
              <b-badge  v-if="itemData.is_portal" variant="primary">Portal</b-badge>
              <b-badge  v-else variant="secondary">In Store</b-badge>
            </b-card-text>
            <b-card-text>
              <span style="color: #0d6efd">Phone Guide</span>
              -
             <b-link
              v-if="ven_type == 'agency'"
              style="color: black"
              :to="{ path: '/ShowPhoneGuide/' + p_ID }"
            >
              {{ Pname }}
            </b-link>
            </b-card-text>
            <b-card-text>
              <span style="color: #0d6efd">Vendor</span>
              -
            <b-link
              v-if="ven_type == 'agency'"
              style="color: black"
              :to="{ path: '/Vendor/Show/' + ven_id }"
            >
              {{ VendName }} - {{ storename }}
            </b-link>
            </b-card-text>
            <hr />
            <div v-if="delCat == true" class="d-flex flex-column flex-sm-row">
              <b-button
                block
                v-if="authPermissions.includes('update-product-status')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="mr-1 mt-1"
                :to="{ path: '/New/UpdateProductMainData/' + itemData.id }"
              >
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                <span>Update Main Data</span>
              </b-button>
              <b-button
                block
                v-b-modal.modal-active
                @click="restoreSelected.variants = []"
                v-show="itemData.status === 'confirmed'"
                v-if="authPermissions.includes('enabled-product')"
                variant="outline-info"
                class="mr-1 mt-1"
              >
                Activate
              </b-button>
              <b-modal
                id="modal-active"
                cancel-variant="outline-secondary"
                @ok="activeProduct(itemData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Activate Product"
              >
                <b-form
                  style="
                    max-height: 500px;
                    overflow-x: hidden;
                    overflow-y: auto;
                  "
                >
                  <b-form-group
                    label="Using options array:"
                    v-slot="{ ariaDescribedby2 }"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      stacked
                      v-model="restoreSelected.variants"
                      :options="options"
                      :aria-describedby="ariaDescribedby2"
                      name="flavour-2"
                    ></b-form-checkbox-group>
                  </b-form-group>
                  <infinite-loading
                    @infinite="getAllVariantsInSystem"
                  ></infinite-loading>
                </b-form>
              </b-modal>

              <b-button
                block
                v-b-modal.modal-deactive
                v-show="itemData.status === 'enabled'"
                v-if="authPermissions.includes('update-product-status')"
                variant="outline-info"
                class="mr-1 mt-1"
              >
                Deactivate
              </b-button>
              <b-modal
                id="modal-deactive"
                cancel-variant="outline-secondary"
                @ok="deActiveProduct(itemData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Deactivate Product"
              >
                <b-form>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="deactive"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                  >
                    Force Deactive
                  </b-form-checkbox>
                </b-form>
              </b-modal>
              <b-button
                block
                v-b-modal.modal-delete
                v-if="authPermissions.includes('archive-product')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-1 mt-1"
              >
                <feather-icon icon="DeleteIcon" class="mr-50" />
                <span>Delete Product</span>
              </b-button>
              <b-modal
                id="modal-delete"
                cancel-variant="outline-secondary"
                @ok="deleteProduct(itemData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Delete Product"
              >
                <b-form>
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="deletedProduct"
                    name="checkbox-2"
                    value="1"
                    unchecked-value="0"
                  >
                    Force Delete
                  </b-form-checkbox>
                </b-form>
              </b-modal>
            </div>

            <div>
              <b-button
                v-if="delCat == false"
                block
                v-b-modal.modal-restore
                @click="resteData"
                variant="outline-danger"
                class="mr-1 mt-1"
              >
                Restore
              </b-button>
              <b-modal
                id="modal-restore"
                cancel-variant="outline-secondary"
                @ok="restoreItem(itemData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Restore Product"
              >
                <b-form
                  style="
                    max-height: 500px;
                    overflow-x: hidden;
                    overflow-y: auto;
                  "
                >
                  <b-form-group
                    label="Using options array:"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      stacked
                      v-model="restoreSelected2.variants"
                      :options="options2"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-1"
                    ></b-form-checkbox-group>
                  </b-form-group>
                  <infinite-loading
                    @infinite="getAllTrashedVariantsInSystem"
                  ></infinite-loading>
                </b-form>
              </b-modal>
            </div>

            <hr />
            <div
              v-if="delCat"
              class="d-flex justify-content-md-center align-items-center text-center"
            >
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Created at</h6>
                <h3 class="mb-0">
                  {{ itemData.created_at }}
                </h3>
              </div>
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Updated at</h6>
                <h3 class="mb-0">
                  {{ itemData.updated_at }}
                </h3>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-content-md-center align-items-center text-center"
            >
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Created at</h6>
                <h3 class="mb-0">
                  {{ itemData.created_at }}
                </h3>
              </div>
              <div class="m-4">
                <h6 class="text-muted font-weight-bolder">Deleted at</h6>
                <h3 class="mb-0">
                  {{ itemData.deleted_at }}
                </h3>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!--start accessories section -->
    <b-card v-if="itemData.is_portal" title="Accessories">
      <div
        v-for="(accessory, index) in itemData.accessories"
        :key="accessory.id + index"
        class="d-inline p-2"
      >
        <span style="font-size: 17px">
          <b-badge
            variant="light-primary"
            style="padding: 10px"
            class="badge-glow badge-lg"
          >
            {{ accessory.translation.name }}
          </b-badge>
        </span>
      </div>
      <div>
        <b-button
          v-if="authPermissions.includes('update-product-accessory')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="warning"
          block
          class="btn-cart mt-3"
          :to="{ path: '/New/Product/UpdateAccessories/' + itemData.id }"
        >
          <feather-icon icon="RefreshCwIcon" class="mr-50" />
          <span>Update</span>
        </b-button>
      </div>
    </b-card>
    <!--end accessories section -->

    <!-- tags section -->
    <b-card v-if="itemData.is_portal" title="Tags">
      <div
        v-for="(tag, index) in itemData.tags"
        class="d-inline p-2"
        :key="index"
      >
        <span style="font-size: 17px">
          <b-badge
            variant="light-primary"
            style="padding: 10px"
            class="badge-glow badge-lg"
          >
            {{ tag.name }}
          </b-badge>
        </span>
      </div>
      <div>
        <b-button
          v-if="authPermissions.includes('update')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="warning"
          block
          class="btn-cart mt-3"
          :to="{ path: '/New/Product/UpdateTags/' + itemData.id }"
        >
          <feather-icon icon="RefreshCwIcon" class="mr-50" />
          <span>Update</span>
        </b-button>
      </div>
    </b-card>
    <!-- Description section -->
    <b-card v-if="itemData.is_portal" title="Description">
      <b-tabs align="center" class="text-center">
        <b-tab
          v-for="(translation, index) in itemTrans"
          :title="translation.locale == 'en' ? 'English' : 'Arabic'"
          :key="index"
        >
          <b-card-text v-html="translation.description"></b-card-text>
          <b-button
            v-if="authPermissions.includes('update')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="warning"
            class="btn-cart"
            :to="{ path: '/New/Product/'+itemData.id+'/UpdateTranslation/' + translation.id }"
          >
            <feather-icon icon="RefreshCwIcon" class="mr-50" />
            <span>Update {{ translation.locale }} translation text</span>
          </b-button>
          <b-button
            v-if="
              authPermissions.includes('delete') &&
              translation.locale != 'en' &&
              translation.locale != 'ar'
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="danger"
            class="btn-cart ml-1"
            @click="deleteTranslation(translation.id)"
          >
            <feather-icon icon="DeleteIcon" class="mr-50" />
            <span>Delete {{ translation.locale }} translation text</span>
          </b-button>
        </b-tab>
      </b-tabs>
      <!-- add new translation -->
      <b-button
        v-if="authPermissions.includes('create')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="success"
        block
        class="btn-cart mt-3"
        @click="
          $router.push({
            path: '/New/Product/addNewTranslation/' + itemData.id,
          })
        "
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span>Add new translation to text</span>
      </b-button>
    </b-card>
  
   
   
    <!-- photo gallery section -->
    <CoolLightBox
      :items="photoGallery"
      :index="imgIndex"
      @close="imgIndex = null"
    ></CoolLightBox>
    <b-card title="Media">
      <b-row>
        <b-col v-if="itemData.is_portal" class="mb-5" md="6">
          <b-button
            v-if="authPermissions.includes('update_product_media') "
            v-b-modal.modal-video
            :variant="videoSect == true ? 'warning' : 'success'"
          >
            {{ videoSect == true ? 'Update Video' : 'Uploade Video' }}
          </b-button>
          <b-modal
            v-if="authPermissions.includes('update_product_media')"
            id="modal-video"
            cancel-variant="outline-secondary"
            @ok="uploadeVideo()"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Uploade Video"
          >
            <b-form
              style="max-height: 500px; overflow-x: hidden; overflow-y: auto"
            >
              <b-form-group
                class="block"
                label="Video (.mp4)"
                label-for="Video"
              >
                <b-form-file
                  type="file"
                  class="form-control-file"
                  multiple="multiple"
                  name="file"
                  id="file"
                  ref="file"
                  change
                  @change="fileUpload"
                  :state="Boolean(files)"
                  accept="video/*"
                />
              </b-form-group>
            </b-form>
          </b-modal>
        </b-col>
        <b-col v-if="videoSect && itemData.is_portal" cols="12">
          <h2>Video</h2>
          <!-- <video-player
            class="video-player-box"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @statechanged="playerStateChanged($event)"
            @ready="playerReadied"
          ></video-player> -->
          <video  controls>
            <source :src="playerOptions.sources[0].src" />
          </video>
        </b-col>
        <b-col class="mt-2" cols="12">
          <h2>Photo Gallery</h2>
          <swiper
            class="swiper-autoplay"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide v-for="(data, index) in photoGallery" :key="index">
              <b-img
                @click="imgIndex = index"
                width="150px"
                height="200px"
                :src="data"
                style="display: block; margin: auto"
              />
            </swiper-slide>
            <div slot="pagination" class="swiper-pagination" />
          </swiper>
          <!-- add new image -->
          <b-button
            v-if="authPermissions.includes('update_product_media') && itemData.is_portal"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="success"
            block
            class="btn-cart mt-3"
            update_product_media
            :to="{ path: '/New/Product/AddNewImage/' + itemData.id }"
          >
            <feather-icon icon="RefreshCwIcon" class="mr-50" />
            <span>Add New Image</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12">
        <b-card title="Variants">
          <b-tabs style="overflow: auto">
            <b-tab @click="trashed = false" title="Variants">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Image</th>
                    <th scope="col">name</th>
                    <!-- <th scope="col">condition</th> -->
                    <th scope="col">status</th>
                    <th scope="col">Sales Channel</th>
                    <th scope="col">Original Price</th>
                    <th scope="col">Profit</th>
                    <th scope="col">Sale Price</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                    <!-- <th scope="col">Images</th> -->
                    <th scope="col">Stock</th>
                    <th scope="col">Show</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(i, n) in itemData.variants" :key="n">
                    <th scope="row">{{ i.id }}</th>
                    <td>
                      <b-avatar :src="i.cover" />
                    </td>
                    <td>{{ i.translation.name }}</td>
                    <td>{{ i.status }}</td>
                    <td><b-badge  v-if="i.is_portal" variant="primary">Portal</b-badge>
              <b-badge  v-else variant="secondary">In Store</b-badge></td>
                    <!-- <td>{{i.condition.name.ar}}</td> -->
                    <td>{{ i.original_price }}</td>
                    <td>{{ i.profit }}</td>
                    <td>{{ i.sale_price }}</td>
                    <td>{{ i.price }}</td>
                    <td>{{ i.quantity }}</td>
                    <td>
                      <div v-if="i.stock_status == 'in-stock'">
                        <b-badge variant="success">
                          {{ i.stock_status }}
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge variant="danger">{{ i.stock_status }}</b-badge>
                      </div>
                    </td>
                    <td class="text-center">
                      <!-- show button item -->
                      <b-button
                        block
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="mr-1 mt-1"
                        :to="{ path: '/New/ShowProduct/variant/show/' + i.id }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-50" />
                        <span>show varaint</span>
                      </b-button>
                      
                      <!-- update button item -->
                      <b-button
                        style="
                          padding: 7px;
                          margin: 6px;
                          width: 35px;
                          height: 35px;
                        "
                        v-if="authPermissions.includes('update')"
                        :to="{
                          path: '/New/ShowProduct/update/variant/' + i.id,
                        }"
                        variant="warning"
                        class="btn-icon rounded-circle"
                      >
                        <feather-icon
                          icon="EditIcon"
                          style="width: 18px; height: 18px"
                        />
                      </b-button>
                      <!-- delete button item -->
                      <b-button
                        style="
                          padding: 7px;
                          margin: 6px;
                          width: 35px;
                          height: 35px;
                        "
                        class="btn-icon rounded-circle"
                        v-b-modal.modal-variant
                        v-if="authPermissions.includes('delete')"
                        size="sm"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        type="submit"
                        @click="setdeleteVariant = i.id"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          style="width: 18px; height: 18px"
                        />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>

           

            <b-tab
              @click="trashed = true"
              v-if="authPermissions.includes('show-trashed')"
              title="Archived Variants"
            >
              <trashed-variants-table
                v-if="trashed"
                :link="'products/' + id + '/variants/trashed/'"
              ></trashed-variants-table>
            </b-tab>
          </b-tabs>
          <b-modal
            id="modal-variant"
            cancel-variant="outline-secondary"
            @ok="deleteVariant(setdeleteVariant)"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Delete Variant"
          >
            <b-form>
              <b-form-checkbox
                id="checkbox-2"
                v-model="deletedVariant"
                name="checkbox-2"
                value="1"
                unchecked-value="0"
              >
                Force Delete
              </b-form-checkbox>
            </b-form>
          </b-modal>
          <b-button
          v-if="itemData.is_portal"
            variant="success"
            :to="{ path: '/New/ShowProduct/create/variant/' + itemData.id }"
            block
            class="mt-2"
          >
            Add New variants
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    
   
  </section>
</template>

<script>
import TrashedVariantsTable from '@/Components/TrashedVariants'
// import axios from '@/libs/axios'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ECommerceProductDetailsRelatedProducts from './ECommerceProductDetailsRelatedProducts.vue'

export default {
  name: 'MyComponent',
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
  },
  components: {
    TrashedVariantsTable,
    ECommerceProductDetailsRelatedProducts,
    CoolLightBox,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      options: [],
      deactive: '0',
      deletedProduct: '0',
      videoSect: false,
      trashed: false,
      playerOptions: {
        // videojs options
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            src: '',
          },
        ],
        poster: '',
      },
      related: [],
      relatedParent: false,
      
      p_ID: 0,
      ven_id: 0,
      ven_type: '',
      brand_id: 0,
      cat_id: 0,
      setdeleteVariant: 0,
      delCat: null,
      itemIDV: '',
      authPermissions: [],
      parentSet: true,
      showImg: false,
      imgIndex: null,
      mainImage: '',
      id: 0,
      itemData: {},
      itemComments: [],
      errors_back: [],
      showDismissibleAlert: false,
      itemTrans: '',
      itemDesc: null,
      itemTrans_name: null,
      itemTrans_name2: null,
      catTrans: null,
      catTrans_name: null,
      brandTrans: null,
      brandTrans_name: null,
      taxonomies: [],
      Catactive: [],
      price: '',
      active: '',
      ShortDesc: '',
      ShortDesc2: '',
      ShortDesc3: '',
      parentSKU: '',
      parentID: 0,
      sku: '',
      average_rate: '',
      VendName: '',
      vendorType: '',
      PhGuide: '',
      PhGuide_Desc: '',
      vendorcover: '',
      parentCover: '',
      phonegudieCover: '',
      VendCode: '',
      length: '',
      width: '',
      files: null,
      height: '',
      weight: '',
      mass_unit: '',
      distance_unit: '',
      storename: '',
      address: '',
      quantity: '',
      Pdesc: '',
      Pname: '',
      aRate: '',
      variants: null,
      loadMore: true,
      option: {
        xAxisData: [],
        series: [],
      },
      photoGallery: [],
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
      },
      deletedVariant: 0,
      items: [],
      stickyHeader: true,
      noCollapse: true,
      fields: [
        {
          key: 'id',
          stickyColumn: true,
          isRowHeader: true,
          variant: 'primary',
        },
        {
          key: 'price',
          label: 'Price',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'created_at',
          label: 'Date',
        },
        {
          key: 'actions',
        },
      ],
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
      currentPage: 0,
      restoreSelected: {
        variants: [],
      },
      currentPage2: 0,
      options2: [],
      restoreSelected2: {
        variants: [],
      },
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.showDismissibleAlert = false
    this.id = this.$route.params.id
    this.fetchData()
    // this.getAllVariantsInSystem()
    // this.getAllTrashedVariantsInSystem()
  },
  methods: {
    fetchData() {
      axios
        .get('products/' + this.id)
        .then((result) => {
          
          
          this.variants = []
          this.itemData = result.data.data
          // //console.log(this.itemData)
          if (this.itemData.deleted_at != null) {
            this.delCat = false
          } else {
            this.delCat = true
          }
          this.itemIDV = this.itemData.id.toString()
          // this.price = this.itemData.price + ' EGP'
          if (this.itemData.translation.length > 0) {
            this.ShortDesc = this.itemData.translation[0].short_description
            this.itemTrans = this.itemData.translation
            this.itemTrans_name = this.itemData.translation[0].name
          }
          this.sku = this.itemData.sku
          this.average_rate = this.itemData.average_rate
          if (this.itemData.category.translation.length > 0) {
            this.catTrans_name = this.itemData.category.translation[0].name
          }
          this.brand_id = this.itemData.brand.id
          this.cat_id = this.itemData.category.id
          if (this.itemData.brand.translation.length > 0) {
            this.brandTrans_name = this.itemData.brand.translation[0].name
          }
          this.VendName =
            this.itemData.vendor.firstname + ' ' + this.itemData.vendor.lastname
          this.VendCode = this.itemData.vendor.code
          if (this.itemData.parent.length > 0) {
            this.parentID = this.itemData.parent[0].id
            if (this.itemData.parent[0].translation != null) {
              this.PhGuide = this.itemData.parent[0].translation.name
              this.ShortDesc2 =
                this.itemData.parent[0].translation.short_description
            }
            if (this.itemData.parent[0].Media.length > 0) {
              this.parentCover = this.itemData.parent[0].Media[0].name
            }
            this.parentSKU = this.itemData.parent[0].sku
            this.aRate = this.itemData.parent[0].average_rate
            this.parentSet = true
          } else {
            this.parentSet = false
          }
          this.ven_id = this.itemData.vendor.id
          this.ven_type = this.itemData.vendor.type
          this.vendorcover = this.itemData.vendor.profile_picture
          this.vendorType = this.itemData.vendor.type
          this.phonegudieCover = this.itemData.phoneGuide.cover
          this.p_ID = this.itemData.phoneGuide.id
          if (this.itemData.shipping != null) {
            this.length = this.itemData.shipping.length
            this.width = this.itemData.shipping.width
            this.height = this.itemData.shipping.height
            this.weight = this.itemData.shipping.weight
            this.distance_unit = this.itemData.shipping.distance_unit
            this.mass_unit = this.itemData.shipping.mass_unit
          }
          this.storename = this.itemData.vendor.storename
          this.address = this.itemData.vendor.address
          this.quantity = this.itemData.quantity
          this.phname = this.itemData.phoneGuide.name
          if (this.itemData.phoneGuide.item_translations.length > 0) {
            this.Pname = this.itemData.phoneGuide.item_translations[0].name
            this.Pdesc =
              this.itemData.phoneGuide.item_translations[0].short_description
          }
          
          this.mainImage = this.itemData.cover
          if (this.itemData.phoneGuide.item_images.length > 0) {
            for (let img in this.itemData.phoneGuide.item_images) {
              if (this.itemData.phoneGuide.item_images[img].type != 'video') {
                this.photoGallery.push(
                  this.itemData.phoneGuide.item_images[img].file
                )
              }
            }
          }
          for (let vid in this.itemData.Media) {
            if (this.itemData.Media[vid].type == 'video') {
              //   //console.log("video");
              this.videoSect = true

              this.playerOptions.sources[0].src = this.itemData.Media[vid].name
            }
          }
          
          
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    fileUpload(e) {
      this.files = e.target.files[0]
    },
    uploadeVideo() {
      let formData = new FormData()
      if (this.files != null) {
        formData.append('video', this.files)
      }
      axios
        .post('/products/' + this.id + '/media/store', formData)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    getAllTrashedVariantsInSystem($state) {
      this.currentPage2 += 1

      axios
        .get(
          'products/' +
            this.id +
            '/variants/trashed/25/sortBy-id-desc?page=' +
            this.currentPage2
        )
        .then((result) => {
          //console.log(result)
          const variants = result.data.data.data
          this.loadMore = false
          if (variants.length > 0) {
            for (let index in variants) {
              if (variants[index].translation != null) {
                this.options2.push({
                  text: variants[index].translation.name,
                  value: variants[index].id.toString(),
                })
              } else {
                this.options2.push({
                  text: 'undefinded',
                  value: variants[index].id.toString(),
                })
              }
            }

            $state.loaded()
            //console.log('loaded')
          } else {
            $state.complete()
          }
          //console.log('completed')
          this.loadMore = false
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    resteData() {
      this.currentPage2 = 0
      this.options2.length = 0
      this.restoreSelected2.variants.length = 0
    },
    getAllVariantsInSystem($state) {
      this.currentPage += 1
      //console.log('hello')
      axios
        .get(
          'products/' +
            this.id +
            '/variants/25/sortBy-id-desc?page=' +
            this.currentPage
        )
        .then((result) => {
          //console.log(result)
          const variants = result.data.data.data
          this.loadMore = false
          if (variants.length > 0) {
            for (let index in variants) {
              if (variants[index].translation != null) {
                this.options.push({
                  text: variants[index].translation.name,
                  value: variants[index].id.toString(),
                })
              } else {
                this.options.push({
                  text: 'undefinded',
                  value: variants[index].id.toString(),
                })
              }
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    deleteVariant(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete variant.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete(
                'variants/' + id + '/archive-variant/' + this.deletedVariant
              )
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteProduct(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete product.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete(
                'products/' +
                  this.id +
                  '/archive-product/' +
                  this.deletedProduct
              )
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    // listen event
    onPlayerPlay(player) {},
    onPlayerPause(player) {},
    // ...player event
    // or listen state event
    playerStateChanged(playerCurrentState) {},
    // player is ready
    playerReadied(player) {
      // you can use it to do something...
      // player.[methods]
    },
    deleteTranslation(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete translation.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('products-guide-translations/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteImages(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete image.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('products-guide-media/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteReview(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete comment.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('products-guide/review/product/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    activeProduct(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to activate product.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .post('products/' + id + '/active-product', this.restoreSelected)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deActiveProduct(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to deactivate product.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('products/' + id + '/deactive-product/' + this.deactive)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restoreItem(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore product.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('products/' + id + '/restore', this.restoreSelected2)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>

<style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px !important;
}
.swiper-container {
  padding: 30px !important;
}
[dir] .video-js {
  width: 100% !important;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
[dir='ltr'] .video-js .vjs-big-play-button {
  left: auto !important;
  top: auto !important;
}
</style>
